<template>
    <div id="CostCenter">
        <v-container fluid app>
            <ZnapTable ref="znapTable"
                :endpoint="endpoint"
                :options="options"
                :tableRows="tableRows"
                :noData="noData"
                @setNoData="setNoData"
                :noDataMessage="noDataMessage"
                @setNoDataMessage="setNoDataMessage"
                :filterConditions="filterConditions"
                @setFilterConditions="setFilterConditions"
                @clearFilters="setFilterConditions"
                :loadTable="loadTable"
                @setLoadTable="setLoadTable"
                @clearNotRequiredOptionsItems="clearNotRequiredOptionsItems"
            >
                <!-- // Filters -->
                <template v-slot:filters>
                    <ZnapFilters ref="znapFilters"
                        :filterComponents="options"
                        :noData="noData"
                        @setSelection="populateFilterConditions"
                        :clearFilters="clearFilters"
                        @setClearFilters="setClearFilters"
                    >
                    </ZnapFilters>
                </template>
            </ZnapTable>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapTable from '@/components/znap/ZnapTable.vue'
import ZnapFilters from '@/components/znap/Filters.vue'

export default {
    name:'CostCenter',

    components: { ZnapTable, ZnapFilters },

    computed: {
        endpoint() {
            return [ this.$ipOrganization, 'cost-center' ]
        },
    },

    data: () => ({
        tableRows: 10000,

        options: [
            {
                column: 'id_company_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Grupo empresarial',
                required: true,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'company-group' ]
            },
            {
                column: 'id_parent_cost_center',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Centro de custo superior',
                required: false,
                filterable: true,
                endpoint: [ Vue.prototype.$ipOrganization, 'cost-center' ]
            },
            {
                column: 'id_cost_center_type',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Tipo do centro de custo',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'cost-center-type' ]
            },
            {
                column: 'id_unit_measurement',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Unidade de medida',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipUnit, 'unit-measurement' ]
            },
            {
                column: 'id_customer_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Grupo do cliente',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipCustomer, 'customer-group' ]
            },
            {
                column: 'id_product',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Projeto',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipProduct, 'product' ]
            },
            {
                column: 'id_sales_force',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Equipe de vendas',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipSales, 'sales-force' ]
            },
            {
                column: 'id_sales_channel',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Canal de vendas',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipSales, 'sales-channel' ]
            },
            {
                column: 'id_sales_force_bp',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'BP',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipSales, 'sales-force' ]
            },
            {
                column: 'id_sales_force_pm',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'PM',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipSales, 'sales-force' ]
            },
            {
                column: 'id_status',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Status',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipUnit, 'status' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: 'status_column_name',
                        operator: "=",
                        value: 'id_status'
                    }
                ]
            },
            {
                column: 'id_status1',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Status 1',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipUnit, 'status' ]
            },
            {
                column: 'id_status2',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Status 2',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipUnit, 'status' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: 'status_column_name',
                        operator: "=",
                        value: 'id_status2'
                    }
                ]
            },
            {
                column: 'id_status3',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Status 3',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipUnit, 'status' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: 'status_column_name',
                        operator: "=",
                        value: 'id_status3'
                    }
                ]
            },
            {
                column: 'id_cost_center_classification',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Classificação do centro de custo',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'cost-center-classification' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: 'classification_column_name',
                        operator: "=",
                        value: 'id_cost_center_classification'
                    }
                ]
            },
            {
                column: 'id_cost_center_classification1',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Classificação do centro de custo 1',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'cost-center-classification' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: 'classification_column_name',
                        operator: "=",
                        value: 'id_cost_center_classification1'
                    }
                ]
            },
            {
                column: 'id_cost_center_classification2',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Classificação do centro de custo 2',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'cost-center-classification' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: 'classification_column_name',
                        operator: "=",
                        value: 'id_cost_center_classification2'
                    }
                ]
            },
            {
                column: 'id_cost_center_classification3',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Classificação do centro de custo 3',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'cost-center-classification' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: 'classification_column_name',
                        operator: "=",
                        value: 'id_cost_center_classification3'
                    }
                ]
            },
        ],

        noDataMessage: '',
        noData: true,

        // Filters
        filterConditions: [],
        loadTable: false,
        clearFilters: false,
    }),

    methods: {
        populateFilterConditions(selectionArray, requiredFilter) {
            this.filterConditions = []

            if (requiredFilter) {
                this.filterConditions.push({
                    AndOr: 'AND',
                    column: selectionArray[0].column,
                    operator: '=',
                    value: selectionArray[0].id,
                    required: true
                })

                this.loadTable = true
            } else {
                if (selectionArray.length !== 0) {
                    selectionArray.forEach(selection => {
                        
                        let required = false
                        this.options.forEach(option => {    
                            if (option.required && selection.column === option.column) {
                                required = true
                            }
                        })

                        if (selection.id === undefined) {
                            this.filterConditions = []
                        } else {
                            if (selection.id.length > 1) {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: 'IN',
                                    value: selection.id,
                                    required
                                })
                            } else {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: '=',
                                    value: selection.id,
                                    required
                                })
                            }             
                        }
                    })
                }
            }
        },

        setNoDataMessage(message) {
            this.noDataMessage = message
        },

        setNoData(payload) {
            this.noData = payload
        },

        setFilterConditions(array) {
            this.filterConditions = array
            
            if (array.length === 0) {
                this.clearFilters = true
            }

            this.options.forEach(option => {
                if (option.required) {
                    this.loadTable = true
                } 
            })
        },

        setClearFilters(payload) {
            this.clearFilters = payload
        },

        clearNotRequiredOptionsItems() {
            this.options.forEach(option => {
                if (!option.required) {
                    option.items = []
                } 
            })
        },

        setLoadTable(payload) {
            this.loadTable = payload
        }
    },
}
</script>

<style>
</style>